import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import ProjectList from '../components/Projects/ProjectList';
import { decodeEntities } from '../utils/helpers';

export default class ProjectIndexPage extends React.Component {
  render() {
    const { data, pageContext, location, path } = this.props;
    const {wordpressPost: page = [], allWordpressWpProjects, categoryFilter, categories, wordpressCategory, wordpressWpSettings, siteSettings } = data;
    const { options = null } = siteSettings
    const { wordpressUrl } = wordpressWpSettings
    const { title, yoast, acf } = page ? page : { title: null, yoast: {}, acf: {} }
    const { siteTitle } = wordpressWpSettings;
    const { edges: posts } = allWordpressWpProjects
    const featuredImage = wordpressCategory && wordpressCategory.acf.featureImage ? wordpressCategory.acf.featureImage : ''
    return (
      <>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `Projects | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
          location={location}
        />
        <PageHeader headerTitle="Projects" location={location} headerBackgroundImage={featuredImage} />
        <ProjectList
          posts={posts}
          title="Latest projects"
          pageContext={pageContext}
          siteMetadata={wordpressWpSettings}
          categories={categories.edges}
          pathPrefix={'/projects/'}
        />
      </>
    )
  }
}

ProjectIndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpProjects: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title,
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    },
    wordpressPost: wordpressPage(slug: {eq: "projects"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    wordpressCategory: wordpressWpProjectCategory(slug: {eq: "uncategorised"}) {
      name
      slug
      path
      description
      acf {
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ... GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        title
      }
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
        }
      }
    }
  }
`
